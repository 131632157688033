#Title {
  color: darkblue;
  margin-left:0px;
  font-family: "Franklin Gothic Medium";
  font-size: 55px;
}

#IOCB {
  color: darkblue;
  margin-left:0px;
  font-size: 15px;
}

#Information {
  padding-top:100px;
  padding-bottom:100px;
  margin-left:150px;
  margin-right:150px;
}

#ulItems {
    margin:0;
    padding:0;
    list-style: none;
    display:flex;
    gap:1rem;
    align-items:center
}

#listItem {
  background-color: rgb(23, 55, 151);
  padding:3px;
  border-radius:5px;
  color:white;
  margin-left:0px;
  font-family: Arial, sans-serif;
  font-size: 15px;
}

#hometable {
  border-bottom: 3px solid lightgrey;
}

#scrollprevious, #scrollnext {
  background-color: white;
  border:none;
}

#seeall, #hideall, #onlymechanism, #notonlymechanism {
  background-color: white;
  border: none;
}

#DoubleDownHome {
  background-color: rgb(23, 55, 151);
  border:none;
  padding-top: 5px;
  padding-bottom: 5px;
}

.responsive, .responsiveCosubstrate {
  width:100%;
  height:auto;
  max-width: var(--image-size, 100px);
  transition: all 0.5s ease-in-out;
  border:none;
}

.responsive:hover {
  transform: scale(var(--hover-scale, 2.0));
  border: 0.5px solid gold;
  z-index: 10;
  position:relative
}

.responsiveCosubstrate:hover {
  transform: scale(2.2);
  border: 0.5px solid gold;
  z-index: 10;
  position:relative
}

#noresults {
  padding-top:80px;
  padding-bottom:100px;
  text-align: center;
  border: solid lightgrey 2px;
  margin-bottom: 25px;
  background-color: rgb(245,245,245);
  box-shadow:0 6px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2)
}

#scrolldownnote {
  position:absolute;
  background-color: rgb(23, 55, 151);
  color: white;
  padding:8px;
  font-size: small;
  opacity: 0;
  transition: all 250ms ease-in-out;
  z-index: -1;
}

#DoubleDownHome:hover ~ #scrolldownnote {
  opacity: 1;
  z-index: 3;
}


#backgroundHome {
  background-image:url("../../background-png.png");
  background-repeat:no-repeat;
  background-size:cover;
  position: relative;
  padding-top: 60px;
  padding-bottom: 30px;
  margin-right: 0px;
  margin-left: 0px;
  box-shadow: 0px 0px 8px 8px rgb(0, 0, 0, 0.2), 0px 0px 6px 6px rgb(0, 0, 0, 0.2)
}

#quicksearch {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top:5px;
  padding-bottom:5px;
  width:100%
}

#quicksearchbutton, #MechanismSearch {
  background-color: rgb(23, 55, 151);
  border: none;
  color: white;
  padding-top:5px;
  padding-bottom:5px;
}

.substrateSmilesCopy, .substrateSmilesCopied,.productSmilesCopy, .productSmilesCopied, .goToChebi, .goToChebiProduct, 
#displaySmilesProduct, .copySequence, .copiedSequence, .speciesDiv,.substrateSmilesCopyMech, .substrateSmilesCopiedMech,.productSmilesCopyMech,
.productSmilesCopiedMech, .goToChebiMech, .goToChebiProductMech {
  position:absolute;
  background-color:rgb(23, 55, 151);
  padding:5px;
  color:white;
  font-size:12px;
  min-width: fit-content;
  width: fit-content;
  z-index:5;
  display:none
}

/* .externalLink {
  position:absolute;
  background-color:rgb(23, 55, 151);
  padding:5px;
  color:white;
  font-size:12px;
  width: max-content;
  z-index:-1;
  opacity:0;
  transition: all 250ms ease-in-out;
} */

.cosubstrateSmilesCopy, .cosubstrateSmilesCopied,.cosubstrate1SmilesCopy, .cosubstrate1SmilesCopied {
  position:absolute;
  background-color:rgb(23, 55, 151);
  padding:5px;
  color:white;
  font-size:12px;
  min-width: fit-content;
  width: fit-content;
  top:1.5rem;
  z-index:5;
  display:none
}

.goToChebiCosubstrate, .goToChebiSubstrate {
  position:absolute;
  background-color:rgb(23, 55, 151);
  padding:5px;
  color:white;
  font-size:12px;
  min-width: fit-content;
  width: fit-content;
  left:0;
  top:1.5rem;
  z-index:5;
  display:none
}

.externalLink  {
  position:absolute;
  background-color:rgb(23, 55, 151);
  padding:5px;
  color:white;
  font-size:12px;
  width: max-content;
  top:-1.75rem;
  left:0;
  right:0;
  z-index:5;
  display:none
}

#terezaEvizens {
  position:absolute;
  background-color:rgb(23, 55, 151);
  padding:5px;
  color:white;
  font-size:12px;
  width: max-content;
  left:0;
  right:0;
  top:-3rem;
  z-index:5;
  display:none;
}

#goToReactionPublication {
  position:absolute;
  background-color:rgb(23, 55, 151);
  padding:5px;
  color:white;
  font-size:12px;
  min-width: fit-content;
  width: fit-content;
  top:-2.2rem;
  z-index:5;
  display:none
}

#goToMechanismPublication {
  position:absolute;
  background-color:rgb(23, 55, 151);
  padding:5px;
  color:white;
  font-size:12px;
  min-width: fit-content;
  right:0;
  top:1.6rem;
  z-index:5;
  display:none
}



#enzNameQuickSearch:hover, #uniprotQuickSearch:hover,#genbankQuickSearch:hover, #typeQuickSearch:hover,#classQuickSearch:hover, #substrateNameQuickSearch:hover, #productNameQuickSearch:hover, #cosubstrateNameQuickSearch:hover {
  text-decoration: underline;
}

#typeQuickSearch:disabled, #classQuickSearch:disabled,#enzNameQuickSearch:disabled, #uniprotQuickSearch:disabled, #genbankQuickSearch:disabled,
#productNameQuickSearch:disabled, #substrateNameQuickSearch:disabled, #cosubstrateNameQuickSearch:disabled {
  color:black
}

#typeQuickSearch:disabled:hover, #classQuickSearch:disabled:hover,#enzNameQuickSearch:disabled:hover, 
#uniprotQuickSearch:disabled:hover, #genbankQuickSearch:disabled:hover, #productNameQuickSearch:disabled:hover,
#substrateNameQuickSearch:disabled:hover, #cosubstrateNameQuickSearch:disabled:hover {
  color:black;
  text-decoration:none
}

#databaseTitle {
  padding-top: -75px;
  color: white;
  margin-left: 150px;
  margin-right: 150px;
  padding-bottom: 15px;
  font-size:14px
}

#quickSearchTitle {
  padding-top:50px;
  color:white
}

#quickSearchInput {
  margin-bottom: 50px;
  margin-right: 150px;
  margin-left: 150px;
}

#quickSearchExamples {
  color:white;
  font-size: 14px;
}

#quickSearchResult {
  margin-right: 150px;
  margin-left: 150px;
  position: relative
}

#nextResultsPrevious {
  list-style: none;
  display: flex;
  justify-content: space-between;
  list-style-position: outside;
  padding-top: 25px;
  padding: 0px;
  margin-top: 25px;
  gap: 15rem;
  margin-bottom: 40px
}

#quickSearchDisplayAllResults {
  display: flex;
  list-style: none;
  padding: 0px;
  justify-content: space-between;
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px
}

#mechDiv {
  margin-left: 150px;
  margin-right: 165px
}

#dataColTwoColumns {
  background-color: rgb(245, 245, 245);
  border:1px solid lightgrey;
  text-align: center;
  word-wrap: break-word;
  width: min-content;
}

#tableTwoColumns {
  break-inside:avoid-column;
  width:100%;
  font-size:12px;
}

#reactionTablesTwoColumns {
  column-count: 1;
  font-size:15px;
  width:100%;
}

#reactionTables {
  column-count: 1;
  font-size:15px;
  width:100%;
  display:table
}

#uniprotQuickSearch, #genbankQuickSearch {
  background-color: rgb(245,245,245);
  border:none;
  margin-top: 0 !important;
}

#entriesButton {
  border: none;
  background-color: rgb(245, 245, 245);
}

#entriesButton:hover {
  text-decoration: underline;
}

#seeEvizens:hover ~ #terezaEvizens {
  display:block
}







@media (max-width: 900px) {
  #databaseTitle {
    padding-top: 0px;
    color: color;
    margin-left: 50px;
    margin-right: 50px;
    padding-bottom: 15px;
    font-size:10px
  }

  #reactionTablesTwoColumns {
    column-count: 1;
    font-size: 12px;
    display:table
  }

  #quickSearchTitle {
    padding-top:25px;
    color:white
  }

  #quickSearchInput {
    margin-bottom: 25px;
    margin-right: 50px;
    margin-left: 50px;
  }

  #quicksearch {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top:5px;
    padding-bottom:5px;
    width:100%
  }

  #quickSearchExamples {
    color:white;
    font-size: 12px;
  }

  #backgroundHome {
    background-image:url("../../background-png.png");
    background-repeat:no-repeat;
    background-size:cover;
    position: relative;
    padding-top: 60px;
    padding-bottom: 10px;
    background-position: 0px 50px;
    box-shadow: 0px 0px 8px 8px rgb(0, 0, 0, 0.2), 0px 0px 6px 6px rgb(0, 0, 0, 0.2) 
  }

  #quickSearchResult {
    margin-right: 50px;
    margin-left: 50px;
    position: relative
  }

  #nextResultsPrevious {
    list-style: none;
    display: flex;
    justify-content: space-between;
    list-style-position: outside;
    padding-top: 25px;
    padding: 0px;
    margin-top: 25px;
    gap: 0rem;
    margin-bottom: 20px
  }

  #noresults {
    padding-top:20px;
    padding-bottom:25px;
    text-align: center;
    border: solid lightgrey 2px;
    margin-bottom: 10px;
    background-color: rgb(245,245,245);
    box-shadow:0 6px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2)
  }

  #quickSearchDisplayAllResults {
    display: flex;
    list-style: none;
    padding: 0px;
    justify-content: space-between;
    gap:0rem;
    margin: 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px
  }  

  #Information {
    padding-top:50px;
    padding-bottom:50px;
    margin-left:50px;
    margin-right:50px;
  }



  #terezaEvizens {
    position:absolute;
    background-color:rgb(23, 55, 151);
    padding:5px;
    color:white;
    font-size:12px;
    min-width: fit-content;
    width: fit-content;
    left:0;
    top:-2.3rem;
    z-index:-1;
    opacity:0;
    transition: all 250ms ease-in-out;
  }

  #mechDiv {
    margin-left: 50px;
    margin-right: 65px
  }

  #dataColTwoColumns {
    background-color: rgb(245, 245, 245);
    border:1px solid lightgrey;
    text-align: center;
    word-wrap: break-word;
    width: min-content;
  }

  #tableTwoColumns {
    break-inside:avoid-column;
    width:100%;
    font-size:12px;
  }

  #headerCol0 {
    padding-top:10px;
    padding-bottom:10px;
    border:1px solid lightgrey;
}

}






