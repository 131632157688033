#HomeNavbar {
    position:fixed;
    width:100vw;
    background-color: rgb(23, 55, 151);
    color: white;
    display:flex;
    justify-content: space-between;
    align-items: center;
    z-index: 200;
}

#homePage, #overview, #signup, #login {
    color:white;
    text-decoration: none;
    height:100%;
}

#login {
    padding:0.5rem;
}

#homePage {
    font-size: 1.2rem;
    font-weight: 400;
    width:150px;
}

#login {
    font-size: 1.2rem;
    font-weight: 400;
}

#ul {
    margin:0 auto;
    padding:0;
    list-style: none;
    display:flex;
    justify-content: space-between;
    gap: 2rem;
    align-items:center;
    margin-right:150px
}

#UL {
    margin:0 auto;
    padding:0;
    list-style: none;
    display:flex;
    gap:0rem;
    align-items:center;
    margin-right:150px;
    margin-left:150px
}

#profileButton {
    background-color:rgb(23, 55, 151,0.0);
    color:white;
}

#MyEntries, #Notifications {
    color: white;
    text-decoration: none;
}

#dropdownProfile {
    position:absolute;
    top: 3rem;
    right:-2rem;
    width:160px;
    padding:10px;
    background-color: rgb(23, 55, 151);
    box-shadow: 2px 2px 4px #000000;

}

#dropdownList {
    color:white;
    text-decoration: none;
}

#dropdownUl {
    margin:0;
    padding:0;
    list-style: none;
    display:flex;
    align-items:center;
    flex-wrap: wrap;
    color:white
}

#dropdownProfile::before {
    content:"";
    position:absolute;
    top:-0.25rem;
    right:4.5rem;
    width:10px;
    height:10px;
    background-color: rgb(23, 55, 151);
    transform:rotate(45deg);

}

#tipsSearch, #tipsSuggest, #tipsEntry, #tipsDownload, #tipsStat {
    background-color: rgb(23, 55, 151);
    color:white;
    padding:10px;
    width:250px;
    top:3.5rem;
    right:0;
    box-shadow: 2px 2px 4px #000000;
    opacity:0;
    z-index: -1;
    transition: ease-in-out 250ms;
}

.tips-navbar {
    background-color: rgb(23, 55, 151);
    color:white;
    padding:10px;
    width:250px;
    top:3.5rem;
    right:-1rem;
    box-shadow: 2px 2px 4px #000000;
    opacity:0;
    z-index: -1;
    transition: ease-in-out 250ms;
}


#dropDownSuggest {
    background-color: rgb(23, 55, 151);
    color:white;
    padding:10px;
    width:100px;
    top:3rem;
    right:-1rem;
    box-shadow: 2px 2px 4px #000000;
}

#dropDownSuggest::before {
    content:"";
    position:absolute;
    top:-0.25rem;
    right:2.75rem;
    width:10px;
    height:10px;
    background-color: rgb(23, 55, 151);
    transform:rotate(45deg);
}

#dropDownEntry {
    background-color: rgb(23, 55, 151);
    color:white;
    padding:10px;
    width:100px;
    top:3rem;
    right:-1.5rem;
    box-shadow: 2px 2px 4px #000000;
}

#dropDownEntry::before {
    content:"";
    position:absolute;
    top:-0.25rem;
    right:2.75rem;
    width:10px;
    height:10px;
    background-color: rgb(23, 55, 151);
    transform:rotate(45deg);
}

#ul button:not(#profileButton) {
    border:none;
    background-color: rgb(23, 55, 151,0.0);
    color:white
}

#profileButton {
    border-top:none;
    border-left:none;
    border-right:none;
    border-bottom:none
}

#UL li {
    border-bottom:5px solid rgb(23, 55, 151,0.0)
}

#UL li:not(#HomeLi):hover {
    border-bottom:5px solid white
}

#profileList, #entryList, #suggestList, #searchList, #downloadList, #statList, .navbar-list{
    border-bottom:5px solid rgb(23, 55, 151,0.0)
}

#profileList:hover, #entryList:hover, #suggestList:hover, #searchList:hover, #downloadList:hover, #downloadList:hover, #statList:hover, .navbar-list:hover {
    border-bottom:5px solid white
}

#dropDownEntry li:hover, #dropDownSuggest li:hover, #dropdownProfile li:hover, .dropdownButton:hover {
    text-decoration: underline;
}


.dropbtnsrch:hover ~ #tipsSearch, .dropbtnsggst:hover ~ #tipsSuggest,.dropbtnntr:hover ~ #tipsEntry, #tipsSearch:hover, .dwnlbtn:hover ~ #tipsDownload, .dropbtnstat:hover ~ #tipsStat, .drop-down-navbar:hover ~ .tips-navbar {
    opacity:1;
    z-index: 6;
}

#HomeLi {
    padding-bottom: 2px;
    padding-top: 10px
}

#LoginLi {
    padding-bottom: 2px;
    padding-top: 2px;
    margin-left: 20px;
}




@media (orientation: portrait) {
    #HomeNavbar {
        position:fixed;
        width:100vw;
        background-color: rgb(23, 55, 151);
        color: white;
        display:flex;
        justify-content: space-between;
        align-items: center;
        z-index: 200;

    }
    #UL {
        margin:0 auto;
        padding:0;
        list-style: none;
        display:flex;
        gap:0rem;
        align-items:center;
        margin-right:0px;
        margin-left:50px;
        vertical-align:middle;
    }
    #ul {
        margin:0 auto;
        padding:0;
        list-style: none;
        display:flex;
        justify-content: space-between;
        gap:0.2rem;
        align-items:center;
        margin-right:50px;
        font-size: 14px;
        vertical-align:middle;
    }

    #homePage {
        font-size: 14px;
        font-weight: 400;
        width:100px;
    }
    
    #login {
        font-size: 14px;
        font-weight: 400;
    }

    #tipsSearch, #tipsSuggest, #tipsEntry, #tipsDownload, #tipsStat {
        background-color: rgb(23, 55, 151);
        color:white;
        padding:10px;
        width:150px;
        top:3.1rem;
        right:0;
        box-shadow: 2px 2px 4px #000000;
        opacity:0;
        z-index: -1;
        transition: ease-in-out 250ms;
    }

    #HomeLi {
        padding-bottom: 4px;
        padding-top: 12px
    }
    
    #LoginLi {
        padding-bottom: 2px;
        padding-top: 3px;
        margin-left: 10px;
    }

}





