
  #statspage {
    padding: 0px 150px;
    padding-top: 60px;
  }

  .stats-layout {
    display: flex;
    justify-content: space-between; 
    align-items: flex-start;
    gap: 20px; 
  }


  .stats-table {
    flex: 1;
    min-width: 300px;
  }
  
  .stats-chart {
    flex: 1;
    min-width: 300px;
  height: 300px;
  }

  @media (orientation: portrait) {
    .stats-layout {
      flex-direction: column;
      gap: 10px;
    }
    .stats-table, .stats-chart {
      min-width: 100%;
      height: auto;
  }

    #statspage {
      padding: 0px 20px;
      padding-top: 60px;
  }
  }
  