.enzyme-record-table {
    display: table;
    width: auto;
    margin-right: auto;
    border-spacing: 0 10px; /* Add spacing between rows */
}

.enzyme-record-row {
    display: table-row;
}

.enzyme-record-label {
    display: table-cell;
    font-weight: bold;
    padding-right: 10px;
    vertical-align: middle;
}

.enzyme-record-divider {
    display: table-cell;
    width: 5px;
    background-color: rgb(23, 55, 151);
    height: 2px;
    vertical-align: middle;
}

.enzyme-record-value {
    display: table-cell;
    padding-left: 10px;
    vertical-align: middle;
}

.amino-sequence {
    display: flex;
    flex-wrap: wrap; /* Ensures blocks wrap properly */
    gap: 15px; /* Spacing between sequence blocks */
    font-family: monospace;
    white-space: pre;
    margin-bottom: 10px;
  }
  
  .sequence-row {
    display: inline-flex; 
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .sequence-numbers {
    font-size: small;
    margin-bottom: 2px;
    display: flex;
    gap: 4px;
  }
  
  .sequence-numbers span {
    display: inline-block;
    width: 5px;
    text-align: center;
  }
  
  .sequence-text {
    font-size: 16px;
    font-weight: bold;
    display: flex;
  }

  
.amino-acid {
    font-weight: bold;
  }

  .sequence-info{
    background-color: rgb(245,245,245);
  }