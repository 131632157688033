.stats-box {
    padding: 10px 30px;
    margin-bottom: 20px;
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: relative;
  }
  
  .stats-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .stats-content {
    padding-top: 10px;
    position: relative;
  }



  #basicpage {
    padding: 0px 150px;
    padding-top: 60px;
  }

  .stats-layout {
    display: flex;
    justify-content: space-between; 
    align-items: flex-start;
    gap: 20px; 
  }


  .stats-table {
    flex: 1;
    min-width: 300px;
  }
  
  .stats-chart {
    flex: 1;
    min-width: 300px;
  height: 300px;
  }

  .tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color:  rgb(23, 55, 151);
    color: white;
    padding: 6px 10px;
    font-size: 14px;
    box-shadow: 2px 2px 4px #000000;
    transition: opacity 0.2s ease-in-out, width 0.2s ease-in-out;
    opacity: 1;
    visibility: visible;
    white-space: normal; 
    width: max-content; 
    max-width: 250px; 
    text-align: center; 
    word-wrap: break-word;
    margin-top: 5px; 
  }
  
  .tooltip-container:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }

  .basic-button {
    background-color: rgb(23, 55, 151);
    color: white;
    padding-top:5px;
    padding-bottom:5px;
    margin-top: 10px;
    margin-right: 3px;
    border: 1px solid rgb(158, 158, 158);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.basic-button:hover {
  background-color: gold;
}

.basic-button:disabled {
  background-color: rgb(180, 180, 180);
  color: rgb(100, 100, 100);
  border: 1px solid rgb(150, 150, 150);
  cursor: not-allowed;
}

.button-container {
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  position: relative;
  bottom: 0; /* Stick to the bottom */
  left: 0;
  right: 0;
}

.wrapped-image {
  float: right; 
  margin-left: 10px; 
}
@media (max-width: 768px) {
  #basicpage {
      padding: 0px 20px;
      padding-top: 60px;
  }

  .stats-box {
      padding: 10px 15px;
      margin-bottom: 15px;
  }

  .stats-title {
      font-size: 16px;
  }

  .stats-layout {
      flex-direction: column;
      gap: 10px;
  }

  .stats-table, .stats-chart {
      min-width: 100%;
      height: auto;
  }

  .basic-button {
      width: 100%;
      margin: 5px 0;
  }

  .button-container {
      flex-direction: column;
      align-items: stretch;
  }
}
