.result-box {
    padding: 8px 10px;
    background-color: rgb(245,245,245);
    border: 1px solid rgb(224, 224, 224);
    border-bottom:2px solid gold;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    position: relative;
}

.remaining-reactions-box{
    padding: 4px 20px;
    background-color: rgb(245,245,245);
    border: 1px solid rgb(224, 224, 224);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    position: relative;
    text-align: right;
}


.result-external-link{
    padding: 10px 20px;
}

.plane-text-button {
    border:none;
    background-color: rgb(245,245,245);
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .plane-text-label {
    border:none;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
  }

.plane-text-button:hover:not(:disabled) {
    text-decoration: underline;
}

.plane-text-button:disabled {
    color: black;
}

.plane-text-button-no-background {
  border:none;
  background-color: white;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 5px;
  padding-right: 5px;
}

.plane-text-button-no-background:hover:not(:disabled) {
  text-decoration: underline;
}
.plane-text-button-no-background:disabled {
  color: black;
}

.enzyme-marts-header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.reaction-box {
    display: flex;
    flex-direction: row;
    background-color: rgb(245,245,245);
    border: 1px solid rgb(224, 224, 224);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    width: 100%; /* Takes full width */
    max-width: 100vw;
  }
  
  /* Left compartment */
  .reaction-info {
    flex: 4;
    display: flex;
    border-right: 1px solid #ddd;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .reaction-source {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  /* Right compartment */
  .reaction-display {
    flex: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #ddd;
  }

  .results-reactions-div {
    margin-bottom: 5px;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 600px) {
    .reaction-box {
      flex-direction: column;
    }
  
    .reaction-info {
      border-right: none;
      border-bottom: 1px solid #ddd;
    }
    .reaction-display {
        border-right: none;
      border-bottom: 1px solid #ddd;
      }
  }