.info_table {
    flex: 1;
    width: 100%;
    border-collapse: collapse;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1); 
}

.info_th, .info_td {
    border: 1px solid lightgrey;
    padding: 8px;
    text-align: left;
}

.info_thead {
    background-color: rgb(235, 235, 235);
    border-bottom: 3px solid gold;
}